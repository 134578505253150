import { FC, JSX, MutableRefObject, useState, useEffect, useRef } from 'react'
import { Box, Button, Container, Grid, Theme, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import HtmlReactParser from 'html-react-parser'
import QRCode from 'react-qr-code'
import Slider from 'react-slick'
import { bannerStyles } from './banner.styles'
import { img, SloganSvg } from '../../../../imgs'
import { useDetectDevice, useReduxSelector } from '../../../../hooks'
import { DetectDevice } from '../../../../types'
import { Login } from '../login'

const custom_pag = (i: any) => {
  return (
    <div className="ts-slick__dots--custom">
      <div className="loading" />
    </div>
  )
}
const custom_dots = (dots: any) => {
  return <ul>{dots}</ul>
}

export const BannerSection: FC = (): JSX.Element => {
  const navigate = useNavigate()
  const detectDevice = useDetectDevice()

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { auth, data: userData } = useReduxSelector((state) => state.auth)
  const { data: constants } = useReduxSelector((state) => state.constants)

  const onNavigate = () => {
    if (auth) navigate('../profile')
    else {
      const element = document.getElementById('login')
      element?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const settings = {
    customPaging: custom_pag,
    speed: 300,
    dots: true,
    appendDots: custom_dots,
    infinite: true,
    easing: 'cubic',
    slidesToShow: 1,
    initialSlide: 0,
    slidesToScroll: 1,
    className: 'custom-slider-white',
    arrows: false,
  }

  return (
    <Box sx={bannerStyles.section}>
      <Container maxWidth="lg">
        <Box id="main" sx={bannerStyles.mainHolder}>
          <img src={sm ? img.banner_sm : img.banner} alt="" />
          <Box sx={bannerStyles.bannerText}>ОТКРОЙ ГЛАЗА НА ГОРЫ</Box>
          {!constants?.IS_PROJECT_STOP_ACTION && (
            <Button sx={bannerStyles.btn} onClick={onNavigate}>
              ЗАРЕГИСТРИРОВАТЬ ЧЕК
            </Button>
          )}
        </Box>
        <Box id="rules" maxWidth="lg" sx={bannerStyles.rules}>
          <Box sx={bannerStyles.rulesLeft}>
            <Box sx={bannerStyles.sectionRulesOption}>
              <Box>1</Box>
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  Покупайте линзы ACUVUE
                  <Box
                    component="span"
                    fontSize={{ xs: '3.33vw', sm: '1.81vw', md: '20px' }}
                    style={{ verticalAlign: 'super' }}
                  >
                    ®
                  </Box>
                </span>
                <Box component="span" sx={bannerStyles.sectionRulesDescription2}>
                  с 1 октября по 31 декабря 2024 года
                </Box>
              </Box>
            </Box>
            <Box sx={bannerStyles.sectionRulesOption} my={{ xs: '4vw', sm: '3vw', md: '0' }}>
              <Box>2</Box>
              <Box>Регистрируйте чеки на сайте</Box>
            </Box>
            <Box sx={bannerStyles.sectionRulesOption}>
              <Box>3</Box>
              <Box>Участвуйте в розыгрыше ежемесячных и главных призов</Box>
            </Box>
            <Box sx={bannerStyles.sectionRulesDescription} mt={{ xs: '4vw', sm: '3vw', md: '0' }}>
              <a href="/backend/files/rules.pdf" target="_blank" rel="noopener noreferrer">
                Полные правила Акции -{'>'}
              </a>
            </Box>
          </Box>
          <Box sx={bannerStyles.rulesRight}>
            <img src={img.rules_gifts} alt="" />
          </Box>
        </Box>
        {!auth && <Login />}
      </Container>
    </Box>
  )
}
